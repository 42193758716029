/*
 * Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { IconModule, OGMiscModule, OGFormsModule } from '@opengamma/ui';
import { NestedTableComponent } from 'app/shared/components/table/nested-table/nested-table.component';
import { OGTableCellModule } from 'app/shared/components/table/table-cells/table-cell.module';
import { TableComponent } from 'app/shared/components/table/table.component';
import { OGLayoutModule } from 'app/shared/components/layout/layout.module';

@NgModule({
  imports: [
    CommonModule,
    IconModule,
    MatTooltipModule,
    FlexLayoutModule,
    OGTableCellModule,
    OGMiscModule,
    OGFormsModule,
    OGLayoutModule,
    MatTooltipModule
  ],
  declarations: [TableComponent, NestedTableComponent],
  exports: [TableComponent, NestedTableComponent]
})
export class OGTableModule {}
