/*
 * Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import auth0 from 'auth0-js';
import jwtDecode from 'jwt-decode';
import { environment } from 'environments/default.environment';
import { Observable, Observer } from 'rxjs';
import { AuthService } from './auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Location } from '@angular/common';
import { carmaApiPath, treasuryApiPath } from 'environments/environment.utils';
import { catchError } from 'rxjs/operators';
import { observableOf, UserAuthModels, UserDetails } from '@opengamma/ui';

/** The type of the decoded JWT. */
interface RenewAuthOptions {
  scope: string;
  responseType: string;
  redirectUri: string;
  usePostMessage: boolean;
  postMessageDataType: string;
  nonce: string;
}

/** The type of the decoded JWT. */
interface JwtToken {
  azp: string;
  sub: string;
}

/** Auth0 implementation of Auth service. */
@Injectable()
export class ApiAuthService extends AuthService {
  readonly webauthOptions = {
    audience: environment.audience,
    domain: environment.auth0Url,
    clientID: ''
  };

  private savedToken: string;
  private authToken: string;

  get idToken(): string {
    return this.savedToken;
  }

  constructor(private client: HttpClient) {
    super();
  }

  permissions(): Observable<UserAuthModels.Permissions> {
    return this.client.get<UserAuthModels.Permissions>(
      Location.joinWithSlash(carmaApiPath(), 'permissions')
    );
  }

  getDemoTenantStatus(tenant: string): Observable<boolean> {
    return this.client
      .get<boolean>(Location.joinWithSlash(carmaApiPath(), 'tenant-demo-status'))
      .pipe(
        catchError(error => {
          console.warn(
            'Could not determine if the current tenant is a demo environment. Assuming this is a production environment. Error: ',
            error
          );
          return observableOf(false);
        })
      );
  }

  getRequestHeaders(): HttpHeaders {
    if (!this.authToken) {
      return undefined;
    }

    return new HttpHeaders().set('Authorization', 'Bearer ' + this.authToken);
  }

  unauthenticate() {
    const subdomain = this.parseSubdomain();
    const subdomainParts = this.getSubdomainParts(subdomain);
    const returnTo = this.createReturnToUrl(subdomainParts);

    try {
      const clientId = localStorage.getItem('auth_client_id');

      localStorage.removeItem('auth_client_sub');
      localStorage.removeItem('auth_client_id');

      if (clientId) {
        const webAuth = new auth0.WebAuth({
          audience: environment.audience,
          domain: environment.auth0Url,
          clientID: clientId
        });

        webAuth.logout({ returnTo });
      }
    } finally {
      window.location.href = returnTo;
    }
  }

  authenticate(): Observable<UserDetails> {
    return new Observable((observer: Observer<UserDetails>) => {
      let clientId: string; // The client ID is required for establishing a connection with Auth0
      const urlParams = this.parseQueryString(window.location.hash.substr(1));
      const apiGatewayToken = urlParams['access_token'];
      const oktaToken = urlParams['id_token'];

      // When logging in from the auth site, the access token is passed in via a hash fragment
      if (apiGatewayToken || oktaToken) {
        try {
          const decodedToken = jwtDecode<JwtToken>(apiGatewayToken);
          clientId = decodedToken.azp;
          localStorage.setItem('auth_client_sub', decodedToken.sub);
        } catch (apiGatewayDecodeError) {
          try {
            const decodedToken = jwtDecode<{ clientID: string }>(oktaToken);
            clientId = decodedToken.clientID;
          } catch (oktaDecodeError) {
            observer.error(
              'Could not decode authentication token: ' + apiGatewayDecodeError + '. ' + oktaToken
            );
          }
        }
      } else {
        // Check whether the user has a saved session
        clientId = localStorage.getItem('auth_client_id');
      }

      if (clientId) {
        localStorage.setItem('auth_client_id', clientId);
        this.renewAuthentication(clientId, observer);
      } else {
        // The user is not logging in and does not have a previous session saved
        observer.error('A valid client id cannot be constructed.');
        observer.complete();
        return undefined;
      }
    });
  }

  private parseSubdomain(): string | undefined {
    try {
      let hostname = window.location.hostname;
      if (hostname.startsWith('www.')) {
        hostname = hostname.substring(4);
      }
      if (hostname !== environment.hostName && hostname.endsWith(environment.hostName)) {
        return hostname.slice(0, hostname.length - environment.hostName.length - 1);
      }
    } catch (e) {
      // fall through.
    }

    return undefined;
  }

  private getSubdomainParts(subdomain: string): Subdomain {
    // Non-preview environment parsing
    let tenantId = subdomain;
    let envPrefix;

    // If in a preview environment, the sub-domain might contain just the environment
    // name, or both the tenant ID and environment name, separated with a dash.
    if (environment.preview && subdomain !== undefined) {
      const parts = subdomain.split('-');
      if (parts.length === 2) {
        // e.g. 'd16-pr123'
        tenantId = parts[0];
        envPrefix = parts[1] + '.';
      } else {
        tenantId = undefined;
        envPrefix = parts[0] + '.';
      }
    }
    return {
      tenantId: tenantId,
      environmentPrefix: envPrefix
    };
  }

  private createReturnToUrl(subdomainParts: Subdomain): string {
    const environmentPrefix = subdomainParts.environmentPrefix;
    const tenantId = subdomainParts.tenantId;

    return (
      `${environment.loginUrl}?redirectUrl=` +
      `${location.protocol || 'http:'}//` +
      `${environmentPrefix || ''}${environment.hostName}` +
      (tenantId ? `&tenantId=${tenantId}` : '')
    );
  }

  private parseQueryString(query): { [key: string]: string } {
    const vars = query.split('&');
    const queryString = {};
    for (const param of vars) {
      const pair = param.split('=');
      // If first entry with this name
      if (typeof queryString[pair[0]] === 'undefined') {
        queryString[pair[0]] = decodeURIComponent(pair[1]);
        // If second entry with this name
      } else if (typeof queryString[pair[0]] === 'string') {
        const arr = [queryString[pair[0]], decodeURIComponent(pair[1])];
        queryString[pair[0]] = arr;
        // If third or later entry with this name
      } else {
        queryString[pair[0]].push(decodeURIComponent(pair[1]));
      }
    }
    return queryString;
  }

  private getEnvironmentPrefix(): string {
    let envPrefix = '';
    if (environment.preview) {
      // We need the full prefix (tenant Id + environment
      // name) when accessing the callback page
      envPrefix = this.parseSubdomain();
      if (envPrefix) {
        envPrefix += '.';
      }
    }
    return envPrefix;
  }

  private getRenewAuthOptions(): RenewAuthOptions {
    const envPrefix = this.getEnvironmentPrefix();
    const redirectUri = `${location.protocol || 'http:'}//${envPrefix || ''}${
      environment.hostName
    }/callback.html`;
    return {
      scope: 'openid profile email user_id',
      responseType: 'token id_token',
      redirectUri: redirectUri,
      usePostMessage: true,
      // This binds the Window.postMessage callback to only the auth0 message, ignoring webpack messages
      // see https://github.com/auth0/auth0.js/commit/ecc17612c4470a60a8e8fbdf227c1d6811cf2b86
      postMessageDataType: 'auth0:silent-authentication',
      nonce: this.randomString(16)
    };
  }

  private renewAuthentication(clientId: string, observer: Observer<UserDetails>): void {
    const webauthOptions = { ...this.webauthOptions, clientID: clientId };
    const webauth = new auth0.WebAuth(webauthOptions);
    webauth.renewAuth(this.getRenewAuthOptions(), (renewAuthErr, auth) => {
      if (renewAuthErr) {
        observer.error(renewAuthErr);
        observer.complete();
        return;
      }
      this.authToken = auth.accessToken;
      this.savedToken = auth.idToken;

      let decodedToken;
      try {
        decodedToken = jwtDecode(this.authToken);
      } catch (decodeError) {
        observer.error('Could not decode renewal token: ' + decodeError);
        observer.complete();
        return;
      }

      localStorage.setItem('auth_client_sub', decodedToken.sub);

      this.fetchUserDetails(webauthOptions, this.authToken, decodedToken, observer);
    });
  }

  private fetchUserDetails(
    webAuthOptions,
    accessToken: string,
    decodedToken: any,
    observer: Observer<UserDetails>
  ) {
    const webauth = new auth0.WebAuth(webAuthOptions);
    webauth.client.userInfo(accessToken, (userInfoErr, user) => {
      if (userInfoErr) {
        observer.error(userInfoErr);
        observer.complete();
        return;
      }

      const mfaKey = Object.keys(user).find(x => x.includes('mfa_enabled'));
      const companyTags = (decodedToken[`${environment.audience}/tags`] || '').split(' ');

      observer.next({
        name: `${user.given_name} ${user.family_name}`,
        email: user.email,
        picture: user.picture,
        isMfaEnabled: !!user[mfaKey],
        organizationId: decodedToken[`${environment.audience}/organization_id`],
        companyName: decodedToken[`${environment.audience}/organization_name`],
        companyTags: companyTags,
        roles: decodedToken[`${environment.audience}/user_roles`] || [],
        jsccTermsConsentRequired:
          decodedToken[`${environment.audience}/jscc_terms_consent_required`]
      });

      observer.complete();
    });
  }

  warmUp(): Observable<void> {
    return this.client.get<void>(Location.joinWithSlash(treasuryApiPath(), 'auth/warm-up'));
  }

  private randomString(length) {
    const bytes = new Uint8Array(length);
    const crypto = window.crypto || (window as any).msCrypto;
    const random = crypto.getRandomValues(bytes);
    const result = [];
    const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._~';

    random.forEach(c => result.push(charset[c % charset.length]));

    return result.join('');
  }
}

/**
 * Holds data about parsed sub-domain before the defined host name.
 *
 * see environment.hostName
 * see environment.preview
 */
interface Subdomain {
  /**
   * The tenant ID, if sub-domain present.
   */
  tenantId?: string;
  /**
   * The environment prefix. Must be defined if this is a preview environment.
   */
  environmentPrefix?: string;
}
