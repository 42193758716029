/*
 * Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATION, RouterNavigationAction } from '@ngrx/router-store';
import { Action, Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { State } from 'app/shared/store/reducers';
import { map, tap, withLatestFrom } from 'rxjs/operators';
import { filterUndefined } from 'app/shared/rxjs/rxjs.utils';
import * as userActions from 'app/user-auth/store/user-auth.actions';
import {
  getScreenUrlByTemplate,
  getProductDefinition
} from 'app/user-auth/store/user-auth.selectors';
import * as _ from 'lodash';
import { getUrlPathSegment } from 'app/user-auth/services/auth/permission.utils';
import {
  FromMarginScreensCommonActions,
  FromMarginScreensCommonSelectors,
  FromTradesApiActions
} from '@opengamma/ui';
import { filterByRoute } from '../utils/router-state-utils';

@Injectable()
export class SharedEffects {
  private readonly themes = {
    margin: 'blue',
    'total-cost': 'red',
    'balance-sheet': 'green',
    capital: 'green'
  };

  /** Sets the page theme based on the page which has been navigated to. */
  @Effect()
  setProductTheme: Observable<Action> = this.actions.pipe(
    ofType<RouterNavigationAction>(ROUTER_NAVIGATION),
    map(action => action.payload.event.url),
    map(url => url.split('/')[1]), // URL always starts with '/', so this is the first part
    map(page => this.themes[page]),
    filterUndefined(),
    map(theme => FromMarginScreensCommonActions.setProductTheme({ theme }))
  );

  /** Sets the module them based on the module which has been navigated to. */
  @Effect()
  setModuleTheme = this.actions.pipe(
    /** Wait on user permissions success as well to ensure product definition is defined  */
    ofType<RouterNavigationAction>(ROUTER_NAVIGATION, userActions.permissions.success),
    withLatestFrom(this.store.select(getProductDefinition)),
    map(([action, definition]) => {
      const activeModuleName = getUrlPathSegment(
        /** On router navigation the url will be taken from the payload, otherwise use global location */
        action.payload?.event.url ?? location.pathname,
        'module'
      );
      const moduleTheme = definition.products
        .flatMap(product => product.modules)
        .find(module => _.startCase(module.moduleName) === _.startCase(activeModuleName))
        ?.displayOptions?.moduleTheme;

      return FromMarginScreensCommonActions.setModuleTheme({ theme: moduleTheme ?? 'dark' });
    })
  );

  /** Invoke the permission service  */
  @Effect()
  recordAccessedUrls = this.actions.pipe(
    ofType<RouterNavigationAction>(ROUTER_NAVIGATION),
    map(action => action.payload.event.url),
    map(url => userActions.recordLastScreenVisitedInModule({ url }))
  );

  @Effect({ dispatch: false })
  redirectToWhatIf = this.actions.pipe(
    ofType(FromMarginScreensCommonActions.exportToWhatIf),
    withLatestFrom(this.store.select(getScreenUrlByTemplate)),
    tap(([, screenUrls]) => {
      this.router.navigate([screenUrls['MARGIN_DYNAMIC_WHATIF']]);
    })
  );

  @Effect()
  loadRebalanceTrades = this.actions.pipe(
    ofType(ROUTER_NAVIGATION),
    filterByRoute(this.store, 'MARGIN_DYNAMIC_WHATIF'),
    withLatestFrom(
      this.store.pipe(
        select(FromMarginScreensCommonSelectors.getWhatIfImportedTradesMetaData),
        filterUndefined()
      )
    ),
    map(
      ([, whatIfImportedTradesMetaData]) =>
        new FromTradesApiActions.LoadImportedTrades(whatIfImportedTradesMetaData)
    )
  );

  constructor(private actions: Actions, private store: Store<State>, private router: Router) {}
}
