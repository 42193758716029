<!--
  ~ Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->

<div class="container-404">
  <span class="box-404">404</span>
  <span class="header-message-404">The page you're looking for cannot be found.</span>

  <div class="content">
    <h3 class="title-404">What to do?</h3>
    <div class="container-message-buttons">
      <span class="message-404">Go back to the previous page</span>
      <button class="buttons" (click)="navigateBack()">Go Back</button>
    </div>

    <h3 class="title-404 secondary-title">If this doesn't work</h3>
    <div class="container-message-buttons ">
      <div class="row-message">
        <span class=" message-404">
          Please contact
          <a href="mailto:support@opengamma.com">support@opengamma.com</a>
          <b>or</b>
          use the support facility to the bottom right of the screen.
        </span>
      </div>
    </div>
  </div>
</div>
