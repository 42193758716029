/*
 * Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import * as Highcharts from 'highcharts/highstock';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { NamedTimeSeries } from 'app/shared/models';
import { defaultTimeseriesOptions } from './options/timeseries.highchart-options';
import { navigator } from './options/navigator.highchart-options';
import { OGHighchartDirective } from 'app/shared/components/charts/modern/highchart/highchart.component';
import * as _ from 'lodash';
import { MARGIN_BLUE_HUES } from 'app/shared/utils/colors';
import { DateTime } from 'luxon';
import { sharedGetCurrencyDescription } from 'app/shared/utils/highcharts-tooltip-formatters';
import { SetExtremesWithDOMEvent } from 'app/shared/components/charts/modern/timeseries-chart/timeseries-chart.model';
import { convertToHighchartsTimeseries } from 'app/shared/components/charts/modern/timeseries-chart/timeseries-chart.utils';
import { TimeSeriesRegion } from 'app/shared/components/charts/legacy/timeseries-chart/timeseries-chart.component';
import {
  DateRange,
  DateTimeService,
  BoundedShortNumberPipe,
  biggestDividerSmallerThanTarget,
  feedbackTypeColorMap,
  PageWidthLayoutService
} from '@opengamma/ui';

@Component({
  selector: 'og-timeseries',
  templateUrl: '../highchart/highchart.component.html',
  styleUrls: ['../highchart/highchart.component.scss', 'timeseries-chart.component.scss']
})
export class OGTimeseriesComponent extends OGHighchartDirective implements OnChanges {
  @Input() type: 'line' | 'area' | 'scatter' = 'line';
  @Input() data: NamedTimeSeries[];
  @Input() colors: string[] = MARGIN_BLUE_HUES;
  @Input() selectedRange: DateRange;
  @Input() hasNavigator = true;
  @Input() plotRegions: TimeSeriesRegion[];
  @Input() enableZoom = false;

  /** The current assumption is that this chart will always render currencies. */
  @Input() isoCode = 'USD';

  @Output() onDrag = new EventEmitter<DateRange>();
  @Output() onDragEnd = new EventEmitter<DateRange>();
  @Output() rangeChange = new EventEmitter<DateRange>();

  @Input() set selectedSeries(series: string[]) {
    this.chart?.series.forEach(chartSeries =>
      chartSeries.setVisible(series.includes(chartSeries.userOptions.id))
    );
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.chart) {
      this.applyOptionSpecificFunctionality(changes);
    } else {
      this.options = _.merge(
        this.options,
        defaultTimeseriesOptions,
        this.createData(),
        this.createEvents(),
        this.createTooltip(),
        this.createYAxisLabelFormatter(),
        this.hasNavigator ? navigator() : {},
        this.plotRegions ? this.createPlotRegion() : {},
        this.rangeChange ? this.createSelectionEvent() : {}
      );
      super.ngOnChanges(changes);
    }
  }

  constructor(
    protected dateTimeService: DateTimeService,
    protected boundedShortNumberPipe: BoundedShortNumberPipe,
    pageWidthLayoutService: PageWidthLayoutService
  ) {
    super(pageWidthLayoutService);
  }

  private applyOptionSpecificFunctionality(simpleChanges: SimpleChanges): void {
    if (simpleChanges.selectedRange) {
      this.chart.xAxis.forEach(axis =>
        axis.setExtremes(
          this.selectedRange.start.toMillis(),
          this.selectedRange.end.toMillis(),
          true,
          false
        )
      );
    }

    const seriesToPlotFromExtraOptions = simpleChanges.extraOptions?.currentValue?.series;
    if (seriesToPlotFromExtraOptions) {
      this.addNewSeries(seriesToPlotFromExtraOptions);
    }

    /**
     * If new series are provided through Input() while the chart component exists, we need to manually update the
     *    chart data.
     */
    const namedTimeSeriesFromDataInput: NamedTimeSeries[] = simpleChanges.data?.currentValue;
    if (namedTimeSeriesFromDataInput) {
      const seriesToPlotFromData = this.generateSeries(namedTimeSeriesFromDataInput);
      this.addNewSeries(seriesToPlotFromData as Highcharts.SeriesOptionsType[]);
    }

    if (simpleChanges.isoCode) {
      this.chart.update(this.createTooltip(), true);
    }
  }

  /**
   * If the chart has a navigator, this function sets margin left on the chart, effectively fixing y axis label width
   *    for a reasonable range of string lengths, as well as taking over number y axis label formatting from highcharts.
   * When dragging through a navigator series, if y axis label widths are allowed to vary, the y axis extremes values
   *    may fluctuate such that there is a difference in y axis label width. This changes the width of the chart
   *    and navigator, shifting the cursor position over the navigator either left or right, causing choppiness and
   *    a poor user experience.
   */
  private createYAxisLabelFormatter(): Highcharts.Options {
    if (!this.hasNavigator) {
      return {};
    }

    const boundedShortNumberPipe = this.boundedShortNumberPipe;
    return {
      chart: { marginLeft: 55 },
      yAxis: {
        labels: {
          formatter: function() {
            if (this.value !== undefined) {
              const value = typeof this.value === 'string' ? parseFloat(this.value) : this.value;
              const boundValue = biggestDividerSmallerThanTarget(Math.abs(value)).value;
              return boundedShortNumberPipe.transform(value, boundValue, '1.0-1');
            }
            return '';
          }
        }
      }
    };
  }

  private createData(): Highcharts.Options {
    if (!this.data) {
      return {};
    }
    return {
      series: this.generateSeries(this.data) as Highcharts.SeriesOptionsType[],
      plotOptions: {
        series: {
          marker: {
            enabled: this.type === 'scatter'
          }
        }
      }
    };
  }

  private generateSeries(data: NamedTimeSeries[]): Highcharts.SeriesOptions[] {
    return data.map((namedSeries, index) => {
      const seriesName = namedSeries.displayName || namedSeries.name;
      return {
        animation: true,
        id: seriesName,
        type: this.type,
        name: seriesName,
        color: this.getChartColor(namedSeries, index),
        fillOpacity: 0.2,
        data: convertToHighchartsTimeseries(namedSeries),
        dashStyle: namedSeries.dashStyle,
        showInNavigator: namedSeries.showInNavigator
      };
    });
  }

  private getChartColor(namedSeries: NamedTimeSeries, index: number): string {
    if (namedSeries.status) {
      return feedbackTypeColorMap[namedSeries.status];
    }
    return namedSeries.color ?? this.colors[index % this.colors.length];
  }

  private addNewSeries(seriesToPlot: Highcharts.SeriesOptionsType[]): void {
    const seriesIds = this.chart.series
      .map(series => series.options.id)
      .filter(id => id && !id.toLowerCase().includes('navigator'));

    for (const id of seriesIds) {
      this.chart.get(id)?.remove();
    }
    for (const series of seriesToPlot) {
      this.chart.addSeries(series);
    }
  }

  private createEvents(): Highcharts.Options {
    return {
      xAxis: {
        min: this.selectedRange?.start?.toMillis(),
        max: this.selectedRange?.end?.toMillis(),
        events: {
          // any is needed as the event is typed incorrectly
          setExtremes: (event: SetExtremesWithDOMEvent) => {
            // Only fire events triggered by the user interacting with the navigator,
            // and not from other sources like the setExtremes function in ngOnChanges
            if (event.trigger === 'navigator') {
              const dateRange = {
                start: DateTime.fromMillis(event.min),
                end: DateTime.fromMillis(event.max)
              };
              this.onDrag.emit(dateRange);
              // navigator drag on the drag area - Dom event exists
              if (event && event.DOMEvent && event.DOMEvent.type === 'mouseup') {
                this.onDragEnd.emit(dateRange);
              } else if (event.triggerOp !== 'navigator-drag') {
                // navigator click outside the drag area - Dom event doesn't exist (for some reason)
                this.onDragEnd.emit(dateRange);
              }
            }
          }
        }
      }
    };
  }

  private createPlotRegion(): Highcharts.Options {
    return {
      xAxis: {
        plotBands: this.plotRegions.map(region => ({
          color: new Highcharts.Color(region.color).setOpacity(region.opacity ?? 1).get(),
          from: Date.parse(region.startDate),
          to: Date.parse(region.endDate)
        }))
      }
    };
  }

  private createSelectionEvent(): Highcharts.Options {
    return {
      chart: {
        events: {
          selection: event => {
            if (event.xAxis) {
              this.rangeChange.emit({
                start: DateTime.fromMillis(event.xAxis[0].min),
                end: DateTime.fromMillis(event.xAxis[0].max)
              });
              return this.enableZoom;
            }
          }
        },
        zoomType: this.rangeChange ? 'x' : undefined
      }
    };
  }

  private createTooltip(): Highcharts.Options {
    // bind these variables here as we lose access to 'this' inside the tooltip formatter function
    const isScatter = this.type === 'scatter';
    const isoCode = this.isoCode;
    const dateTimeService = this.dateTimeService;

    // We assume that we are plotting currencies.
    const formatPoint = point =>
      sharedGetCurrencyDescription({
        price: point.y,
        isoCode
      });

    const tooltipOptions: Highcharts.Options = {};
    // Highcharts decided it'd be too nice to have the same tooltip interface for all series types
    if (isScatter) {
      tooltipOptions.tooltip = {
        formatter: function() {
          const date = dateTimeService.getShortDate(DateTime.fromMillis(this.point.x));
          return `<b>${this.series.name}</b>: ${formatPoint(this.point)}<br><b>Date</b>: ${date}`;
        }
      };
    } else {
      tooltipOptions.tooltip = {
        formatter: function() {
          const date = dateTimeService.getShortDate(DateTime.fromMillis(this.x));
          let tooltip = [`<b>${date}</b>`];
          this.points.forEach(point => {
            tooltip = tooltip.concat(`<b>${point.series.name}</b>: ${formatPoint(point)}`);
          });
          return tooltip;
        }
      };
    }
    return tooltipOptions;
  }
}
