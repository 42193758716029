<!--
  ~ Copyright (C) 2018 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->

<div
  class="header__container header-height"
  fxLayout="column"
  fxLayoutAlign="center"
  *ngIf="products$ | async as products"
>
  <div
    class="header header--{{ pageLayout$ | async }}"
    fxLayout="row"
    fxLayoutAlign="space-between center"
    *ngIf="theme$ | async as theme"
  >
    <div fxLayout="row" fxLayoutGap="32px" fxFlex="1 1 100%" fxLayoutAlign="left center">
      <div fxLayout="column" style="position: relative;">
        <a [routerLink]="['/']" class="header__logo" [ngSwitch]="theme">
          <img *ngSwitchCase="'blue'" class="logo__img" src="assets/logos/solid-blue-open.png" />
          <img *ngSwitchCase="'red'" class="logo__img" src="assets/logos/pink-open.svg" />
          <img *ngSwitchCase="'green'" class="logo__img" src="assets/logos/green-open.svg" />
        </a>
        <og-uat-badge [userDetails]="userDetail$ | async"></og-uat-badge>
      </div>
      <div class="divider" fxFlex="0 0 1px"></div>
      <og-modules-menu
        [theme]="theme"
        [modules]="modules$ | async"
        [productUrl]="selectedProduct$ | async"
      ></og-modules-menu>
      <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="center center">
        <a
          *ngIf="treasuryProduct$ | async as treasuryProduct"
          [href]="treasuryProduct.urlPath"
          [attr.target]="treasuryProduct.isExternal && '_blank'"
          class="utility-button utility-button--new-experience"
        >
          <og-icon name="repeat2"></og-icon>
        </a>
        <div class="utility-button" (click)="togglePageLayout()">
          <og-icon
            *ngIf="pageLayout$ | async as layout"
            [name]="layout === 'fixed' ? 'arrows-diagonal' : 'arrows-diagonal-minimize-2'"
            [matTooltip]="(layout === 'fixed' ? 'Expand' : 'Compress') + ' page elements'"
          ></og-icon>
        </div>
        <a
          [routerLink]="userGuideRef$ | async"
          target="_blank"
          class="utility-button"
          (click)="onUserDownloadClick()"
          [class.utility-button--new]="isFeatureNew()"
        >
          <og-icon name="help-circle-filled" matTooltip="Download user guide"></og-icon>
        </a>
        <og-service-desk
          *ngIf="permissionToDisplayServiceDesk$ | async"
          class="utility-button service-desk"
        ></og-service-desk>
      </div>
    </div>
  </div>
</div>

<og-screens-menu
  [theme]="theme$ | async"
  [displayDatepicker]="true"
  [displayFileViewerButton]="displayFileViewerButton$ | async"
  [displayScreenMenu]="displayScreenMenu$ | async"
  *ngIf="screens$ | async as screens"
>
  <og-screens-menu-items
    [screens]="screens"
    [productUrl]="selectedProduct$ | async"
    [moduleUrl]="selectedModule$ | async"
  ></og-screens-menu-items>
</og-screens-menu>
