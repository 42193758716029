/*
 * Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef
} from '@angular/core';
import {
  CellDataType,
  CellForTemplate,
  CustomCellTemplates,
  DecoratedRow,
  FlatViewMetaData,
  IconStroke,
  NumericBound,
  PagesDisplayed,
  SortData,
  SortIconType,
  TableColumn,
  TableColumnGroup,
  TableRow
} from '@opengamma/ui';
import * as _ from 'lodash';
import {
  convertRowGroup,
  filterRows,
  getNewExpansionSet,
  getSortData,
  sortRows,
  transformColumnsToFlatView,
  transformRowsToFlatView
} from 'app/shared/components/table/utils/table.utils';
import { BehaviorSubject, Observable } from 'rxjs';
import { filterUndefined, observableCombineLatest, observableOf } from 'app/shared/rxjs/rxjs.utils';
import { map, shareReplay, tap } from 'rxjs/operators';

export interface ExpansionMetaData {
  level: number;
  path: string[];
  id: string;
}

/**
 * A table for rendering tree-like data, using templates to display pre-defined cell types.
 */
@Component({
  selector: 'og-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableComponent {
  /** A total row to be displayed at the top of the table. */
  _totalRow: DecoratedRow[];

  /** Determines the order of magnitude of bounded number values to be displayed. */
  _bound: NumericBound = 1e6;

  /** All the table columns groups */
  groups$: Observable<TableColumnGroup[]>;
  /** All the table columns, ungrouped */
  columns$: Observable<TableColumn[]>;

  /** The number of pages each expanded level has loaded into view. */
  pagesDisplayedForPath: PagesDisplayed = {};

  /** Metadata for the currently sorted column */
  sortData$ = new BehaviorSubject<SortData>(undefined);
  /** Contains the ids of all expanded rows */
  rowExpansionSet$ = new BehaviorSubject<Set<string>>(new Set());
  /** A text filter, used to filter the table rows. */
  textFilter$ = new BehaviorSubject<string>('');
  /** Decorated rows that are to be rendered. */
  rows$: Observable<DecoratedRow[]>;

  /** Emits when a row is expanded. */
  @Output() expansionChange = new EventEmitter<ExpansionMetaData>();

  /** Emits when the user sorts by a different column. */
  @Output() sortingChange = new EventEmitter<SortData>();

  /** Emits when the user filters table data. */
  @Output() filteringChange = new EventEmitter<string>();

  /** True if multiple rows can be expanded at the same time. */
  @Input() allowMultipleExpandingRows = true;

  /** Whether the table has to manage its own overflow given the parent is a flex container with no defined width */
  @Input() hasFixedOverflow = false;

  /** The number of rows to display before showing show more */
  @Input() rowPaginationCount = 25;

  @Input() defaultSorting: SortData;

  @Input() customCellTemplates: CustomCellTemplates;

  @Input() customHeaderCellTemplates: CustomCellTemplates;

  @Input() customRowTemplate: TemplateRef<any>;

  @Input() expansionIconColor: string;

  @Input() customRowDecorator: (rows: TableRow[], expansionSet: Set<string>) => DecoratedRow[];

  /**
   * Prevent TreeTableComponent from sorting data. TreeTableComponent will still emit sortingChange
   *    events. Thus, sorting can be handled outside of the component.
   */
  @Input() exportSorting = false;
  @Input() exportFiltering = false;

  _flatViewMetaData$ = new BehaviorSubject({} as FlatViewMetaData);
  @Input() set flatViewMetaData(flatViewMetaData: FlatViewMetaData) {
    if (flatViewMetaData) {
      this._flatViewMetaData$.next(flatViewMetaData);
    }
  }

  @Input() set textFilter(filter: string) {
    this.textFilter$.next(filter);
    this.cdRef.markForCheck();
    this.filteringChange.emit(filter);
  }

  @Input() set rows(rows: TableRow[]) {
    const transformedRows$ = observableCombineLatest([
      this._flatViewMetaData$,
      observableOf(rows).pipe(filterUndefined())
    ]).pipe(
      map(([flatViewMetaData, tableRows]) =>
        _.isEmpty(flatViewMetaData)
          ? tableRows
          : transformRowsToFlatView(tableRows, flatViewMetaData)
      )
    );

    const sortedRows$ = observableCombineLatest([transformedRows$, this.sortData$]).pipe(
      map(([tableRows, sortData]) =>
        this.exportSorting ? tableRows : sortRows(tableRows, sortData)
      )
    );

    const filteredRows$ = observableCombineLatest([sortedRows$, this.textFilter$]).pipe(
      map(([sortedRows, textFilter]) =>
        this.exportFiltering ? sortedRows : filterRows(sortedRows, [textFilter])
      )
    );

    this.rows$ = observableCombineLatest([filteredRows$, this.rowExpansionSet$]).pipe(
      map(([filteredRows, expansionSet]) =>
        this.customRowDecorator
          ? this.customRowDecorator(filteredRows, expansionSet)
          : convertRowGroup(filteredRows, expansionSet)
      )
    );
  }

  @Input() set totalRow(totalRow: TableRow) {
    const convertedTotalRow = this.customRowDecorator
      ? this.customRowDecorator([totalRow], undefined)
      : convertRowGroup([totalRow], undefined);

    this._totalRow =
      convertedTotalRow && convertedTotalRow[0] && !_.isEmpty(convertedTotalRow[0].values)
        ? convertedTotalRow
        : undefined;
  }

  /**
   * The column groups of the table. From these, we derive the table columns.
   * The order of the columns dictates the order that they will be displayed in.
   */
  @Input() set columnGroups(groups: TableColumnGroup[]) {
    const groupsWithFlatViewMetaData$ = observableCombineLatest([
      observableOf(groups),
      this._flatViewMetaData$
    ]).pipe(
      tap(() => {
        if (!this.sortData$.value && this.defaultSorting) {
          this.sortData$.next(this.defaultSorting);
        }
      })
    );

    this.groups$ = groupsWithFlatViewMetaData$.pipe(
      map(([pipedGroups, flatViewMetaData]) => {
        if (_.isEmpty(flatViewMetaData) || !flatViewMetaData?.renderLeafView) {
          return pipedGroups;
        }
        return transformColumnsToFlatView(pipedGroups, flatViewMetaData);
      }),
      shareReplay(1)
    );

    this.columns$ = this.groups$.pipe(
      map(pipedGroups =>
        pipedGroups.reduce((types, columnGroup) => [...types, ...columnGroup.columns], [])
      ),
      shareReplay(1)
    );

    this.cdRef.markForCheck();
  }

  /** The ids of the rows that should be expanded. */
  @Input() set expandedRowsIds(rowIds: string[]) {
    this.rowExpansionSet$.next(new Set(rowIds));
  }

  @Input() set bound(bound: NumericBound) {
    this._bound = bound;
    this.cdRef.markForCheck();
  }
  get bound(): NumericBound {
    return this._bound;
  }

  /** Icon Stroke enum */
  IconStroke = IconStroke;

  constructor(private cdRef: ChangeDetectorRef) {}

  onRowExpand(path: string[], rowId: string, level: number): void {
    const newExpansionSet = getNewExpansionSet(
      [...path, rowId],
      this.rowExpansionSet$.value,
      this.allowMultipleExpandingRows
    );
    this.rowExpansionSet$.next(newExpansionSet);
    this.expansionChange.emit({
      id: rowId,
      path,
      level
    });
  }

  onRowSort(column: TableColumn): void {
    const newSortData = getSortData(column, this.sortData$.value);
    this.sortData$.next(newSortData);
    this.emitColumnSortingEvent(column, newSortData);
  }

  onShowMoreRows(path: string[]): void {
    const pathAsString = this.getPathAsString(path);
    this.pagesDisplayedForPath = {
      ...this.pagesDisplayedForPath,
      [pathAsString]: this.pagesDisplayedForPath[pathAsString]
        ? this.pagesDisplayedForPath[pathAsString] + 1
        : 2
    };
  }

  getNumberOfRowsToDisplayForPath(path: string[]): number {
    return this.rowPaginationCount * (this.pagesDisplayedForPath[this.getPathAsString(path)] || 1);
  }

  emitColumnSortingEvent(column: TableColumn, sortData: SortData): void {
    const columnSortingEvent: SortData = {
      columnId: column.id,
      columnType: column.type,
      sortOrder: sortData.sortOrder
    };
    this.sortingChange.emit(columnSortingEvent);
  }

  trackColumns(index: number, column: TableColumn): string {
    return column?.id;
  }

  trackRows(index: number, row: TableRow): string {
    return row?.id;
  }

  getSortIcon(columnId: string): SortIconType {
    if (!this.sortData$.value || columnId !== this.sortData$.value.columnId) {
      return 'selector';
    }
    return this.sortData$.value.sortOrder === 'asc' ? 'chevron-up' : 'chevron-down';
  }

  getPathAsString(path: string[]): string {
    return path.join('/');
  }

  getCellForTemplate(row: DecoratedRow, { id, type }: TableColumn): CellForTemplate {
    return { value: (row?.values)[id] as CellDataType, type: type };
  }

  getCustomAlignment(first: boolean, column: TableColumn): string {
    switch (column?.options?.alignment) {
      case 'CENTER':
        return 'center center';
      case 'LEFT':
        return 'start center';
      case 'RIGHT':
        return 'end center';
      default:
        if (first || column.type === 'text') {
          return 'start center';
        }
        return 'center center';
    }
  }

  shouldDisplayCustomColumnTemplate(columnId: string): boolean {
    return (
      this.customHeaderCellTemplates !== undefined &&
      this.customHeaderCellTemplates[columnId] !== undefined
    );
  }

  shouldDisplayCustomCellTemplate(columnId: string, treeLevel: number): boolean {
    return (
      this.customCellTemplates &&
      this.customCellTemplates[columnId] &&
      (!this.customCellTemplates[columnId].targetTreeLevels ||
        this.customCellTemplates[columnId].targetTreeLevels.includes(treeLevel))
    );
  }

  shouldDisplayCustomRowTemplate(row: DecoratedRow): boolean {
    return this.customRowTemplate !== undefined && row.displayCustomRowTemplate && row.isExpanded;
  }
}
