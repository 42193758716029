/*
 * Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FlexLayoutModule } from '@angular/flex-layout';
import { TableBoundsSelectComponent } from 'app/shared/components/table-wrapper/table-bounds-select/table-bounds-select.component';
import { TableTextFilterComponent } from 'app/shared/components/table-wrapper/table-text-filter/table-text-filter.component';
import { TableWrapperComponent } from 'app/shared/components/table-wrapper/table-wrapper.component';
import { IconModule, OGFormsModule, OGMiscModule } from '@opengamma/ui';
import { TableColumnsSelectComponent } from 'app/shared/components/table-wrapper/table-columns-select/table-columns-select.component';
import { TableFlatViewSelectComponent } from 'app/shared/components/table-wrapper/table-leaf-view-select/table-leaf-view-select.component';
import { TableExportButtonComponent } from './table-export-button/table-export-button.component';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

@NgModule({
  imports: [
    CommonModule,
    MatTooltipModule,
    IconModule,
    FlexLayoutModule,
    OGFormsModule,
    OGMiscModule
  ],
  declarations: [
    TableWrapperComponent,
    TableTextFilterComponent,
    TableBoundsSelectComponent,
    TableColumnsSelectComponent,
    TableFlatViewSelectComponent,
    TableExportButtonComponent
  ],
  exports: [
    TableWrapperComponent,
    TableTextFilterComponent,
    TableBoundsSelectComponent,
    TableColumnsSelectComponent,
    TableFlatViewSelectComponent,
    TableExportButtonComponent
  ]
})
export class OGTableWrapperModule {}
