/*
 * Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BoundedNumberValueCellComponent } from 'app/shared/components/table/table-cells/bounded-number-value-cell/bounded-number-value-cell.component';
import { CurrencyValueCellComponent } from 'app/shared/components/table/table-cells/currency-value-cell/currency-value-cell.component';
import { PercentageValueCellComponent } from 'app/shared/components/table/table-cells/percentage-value-cell/percentage-value-cell.component';
import { SubtextComponent } from 'app/shared/components/table/table-cells/subtext/subtext.component';
import { TableCellComponent } from 'app/shared/components/table/table-cells/table-cell.component';
import { ShortNumberValueCellComponent } from 'app/shared/components/table/table-cells/short-number-value-cell/short-number-value-cell.component';
import { NumberValueCellComponent } from './number-value-cell/number-value-cell.component';
import { IconCellComponent } from 'app/shared/components/table/table-cells/icon-cell/icon-cell.component';
import { OGLayoutModule } from 'app/shared/components/layout/layout.module';
import {
  OGPipesModule,
  IconModule,
  OGMiscModule,
  OGFormsModule,
  EntryFieldModule
} from '@opengamma/ui';
import { OGMetricsModule } from 'app/shared/components/metrics/metrics.module';
import { NgxPopperjsModule } from 'ngx-popperjs';

@NgModule({
  imports: [
    CommonModule,
    IconModule,
    MatTooltipModule,
    FlexLayoutModule,
    OGPipesModule,
    OGLayoutModule,
    OGMetricsModule,
    OGMiscModule,
    OGFormsModule,
    EntryFieldModule,
    NgxPopperjsModule
  ],
  declarations: [
    BoundedNumberValueCellComponent,
    ShortNumberValueCellComponent,
    CurrencyValueCellComponent,
    PercentageValueCellComponent,
    NumberValueCellComponent,
    SubtextComponent,
    TableCellComponent,
    IconCellComponent
  ],
  exports: [
    BoundedNumberValueCellComponent,
    ShortNumberValueCellComponent,
    CurrencyValueCellComponent,
    PercentageValueCellComponent,
    NumberValueCellComponent,
    TableCellComponent,
    IconCellComponent
  ]
})
export class OGTableCellModule {}
