/*
 * Copyright (C) 2018 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { OGHighchartDirective } from 'app/shared/components/charts/modern/highchart/highchart.component';
import * as _ from 'lodash';
import { defaultBarOptions } from 'app/shared/components/charts/modern/bar-chart/options/bar.highchart-options';

/** A Bar point to render on the charts */
export interface BarPoint {
  /** The bar point id */
  id: string;
  /** The bar name */
  name: string;
  /** The y value for the point */
  y: number;
  /** The color to use for coloring the bar */
  color?: string;
}

@Component({
  selector: 'og-barchart',
  templateUrl: '../highchart/highchart.component.html',
  styleUrls: ['../highchart/highchart.component.scss']
})
export class BarchartComponent extends OGHighchartDirective implements OnChanges {
  /** The date points for the chart. */
  @Input() items: BarPoint[];
  /** Whether to hide or show the legend. */
  @Input() hideLegend = false;

  ngOnChanges(changes: SimpleChanges): void {
    this.options = _.merge(
      this.options,
      defaultBarOptions,
      {
        xAxis: {
          categories: this.items.map(series => series.name)
        }
      },
      {
        legend: {
          enabled: !this.hideLegend
        }
      },
      this.createData()
    );
    super.ngOnChanges(changes);
  }

  private createData(): Highcharts.Options {
    return {
      series: [
        {
          type: 'bar',
          data: this.items.map(series => ({
            name: series.name,
            type: 'string',
            y: series.y,
            color: series.color
          }))
        }
      ]
    };
  }
}
