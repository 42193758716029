/*
 * Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { SILVER, TUNDORA, WHITE } from 'app/shared/utils/colors';
import * as Highcharts from 'highcharts/highstock';

export const defaultBarOptions: Highcharts.Options = {
  chart: {
    type: 'bar',
    spacingTop: 0,
    spacingBottom: 0
  },
  xAxis: {
    type: 'category',
    labels: {
      enabled: true,
      style: {
        align: 'left',
        color: SILVER
      }
    }
  },
  yAxis: {
    gridLineColor: TUNDORA,
    lineWidth: 1,
    labels: {
      enabled: true,
      style: {
        color: SILVER,
        textOverflow: 'none'
      },
      rotation: 0
    }
  },
  plotOptions: {
    bar: {
      borderWidth: 0,
      maxPointWidth: 20,
      dataLabels: {
        enabled: true,
        style: {
          color: WHITE,
          fontWeight: 'normal'
        }
      },
      enableMouseTracking: false
    }
  },
  tooltip: {
    enabled: true
  }
};
