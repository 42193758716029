/*
 * Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {
  MagicTableColumnType,
  MagicTableOptions,
  MagicTableValue,
  MagicTableIconCell,
  MagicTableautoBoundNumber,
  MagicTableautoBoundCurrencyValue,
  MagicTableSubtext
} from 'app/shared/legacy-components/display-structures/magic-table/models';
import * as _ from 'lodash';
import { CurrencyValue, IconStroke } from '@opengamma/ui';
import {
  MagicTableDirectionalBarChart,
  MagicTableDirectionalBarChartDirection
} from 'app/shared/legacy-components/display-structures/magic-table/models/magic-table.models';
import getSymbolFromCurrency from 'currency-symbol-map';

/** Magic table cell. */
@Component({
  selector: 'og-magic-table-value',
  templateUrl: './magic-table-value.component.html',
  styleUrls: ['./magic-table-value.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MagicTableValueComponent implements OnChanges, OnInit, AfterViewChecked {
  /** The value on the cell. */
  @Input() value: MagicTableValue;
  /** The options on the cell. */
  @Input() options: MagicTableOptions;
  /** The type of the value in the cell. */
  @Input() type: MagicTableColumnType;
  /** The bound, used only if the type is 'autoBoundCurrency', 'autoBoundNumber' or 'fixedBoundNumber'. */
  @Input() bound?: number;
  /** The maximum bar value, used if the type is 'directionalBarChart'. */
  @Input() maxBarValue?: number;
  /** The level in the expanded table at which this cell is */
  @Input() level: number;
  /** True if the value is being edited by the user. */
  @Input() isEditing: boolean;
  /** True if the cell row is expanded. */
  @Input() isExpanded: boolean;
  /** The value the user has edited. */
  @Input() editedValue: MagicTableValue;
  /** Secondary text to be added to the cell */
  @Input() subtext: MagicTableSubtext;

  /**
   * True if the cell is part of an expandable column.
   * If true, an expand icon will be prepended to the value.
   */
  @Input() isExpandable: boolean;
  /**  True if a placeholder should be displayed */
  @Input() displayPlaceholder: boolean;
  /** Emits when the user submits a value. */
  @Output() editedValueChange = new EventEmitter<MagicTableValue>();

  // -------------------------------------------------------------------------
  @HostBinding('class.editable') editable: boolean;
  @ViewChild('input') input: ElementRef;

  /// -------------------------------------------------------------------------
  lastEditedValue: MagicTableValue;
  arrowValue: number;
  IconStroke = IconStroke;

  parseDate(date?: string): Date {
    if (!date) {
      return undefined;
    }
    return new Date(Date.parse(date));
  }

  isValueChanged(a: MagicTableValue, b: MagicTableValue): boolean {
    return JSON.stringify(a) !== JSON.stringify(b);
  }

  onClearClick(event) {
    event.stopPropagation();
    this.resetEditedValue();
    this.updateLastEditedValue();
    this.editedValueChange.emit(this.value);
  }

  onBlur() {
    this.editedValueChange.emit(this.editedValue);
    this.updateLastEditedValue();
  }

  onKeyUp(event) {
    if (event.keyCode === 13) {
      this.editedValueChange.emit(this.editedValue);
      this.updateLastEditedValue();
    }
  }

  onEditPercentage(event) {
    this.editedValue = event.target.value / 100;
  }

  ngOnChanges() {
    this.editable = this.options.editable;

    if (!!this.value) {
      if (this.type === 'currency' || this.type === 'autoBoundCurrency') {
        this.arrowValue = (<CurrencyValue>this.value).price;
      } else if (this.type === 'number' || this.type === 'percent') {
        this.arrowValue = <number>this.value;
      }
    }
  }

  ngAfterViewChecked() {
    if (this.isEditing) {
      this.input.nativeElement.focus();
    }
  }

  updateLastEditedValue() {
    if ((this.type === 'currency' || this.type === 'autoBoundCurrency') && !!this.editedValue) {
      this.lastEditedValue = { ...(<CurrencyValue>this.editedValue) };
    } else if (this.type === 'icon' && !!this.editedValue) {
      this.lastEditedValue = { ...(<MagicTableIconCell>this.editedValue) };
    } else if (this.type === 'fixedBoundNumber' && !!this.editedValue) {
      this.lastEditedValue = {
        ...(<MagicTableautoBoundNumber>this.editedValue)
      };
    } else if (this.type === 'fixedBoundCurrency' && !!this.editedValue) {
      this.lastEditedValue = {
        ...(<MagicTableautoBoundCurrencyValue>this.editedValue)
      };
    } else {
      this.lastEditedValue = this.editedValue;
    }
  }

  resetEditedValue() {
    if ((this.type === 'currency' || this.type === 'autoBoundCurrency') && !!this.editedValue) {
      this.editedValue = { ...(<CurrencyValue>this.value) };
    } else if (this.type === 'icon' && !!this.editedValue) {
      this.editedValue = { ...(<MagicTableIconCell>this.value) };
    } else {
      this.editedValue = this.value;
    }
  }

  computeCustomCellStyles(): Object {
    return {
      'max-width': `${this.options.width}px`
    };
  }

  isValueUndefinedOrEmpty() {
    return (
      this.lastEditedValue === undefined ||
      this.lastEditedValue === null ||
      (!!(<any>this.lastEditedValue).length && _.isEmpty(this.lastEditedValue)) ||
      (!!(<MagicTableautoBoundNumber | MagicTableautoBoundCurrencyValue>this.lastEditedValue)
        .bound &&
        !(<MagicTableautoBoundNumber | MagicTableautoBoundCurrencyValue>this.lastEditedValue).value)
    );
  }

  isValueNumeric() {
    return typeof this.lastEditedValue === 'number';
  }

  getDirectionalBarWidth(
    barChart: MagicTableDirectionalBarChart,
    direction: MagicTableDirectionalBarChartDirection
  ): string {
    const scaled =
      this.maxBarValue > 100 ? (barChart.value * 100) / this.maxBarValue : barChart.value;
    return barChart.direction === direction ? scaled + '%' : '0px';
  }

  getCurrencySymbol(currency: CurrencyValue): string {
    if (currency.symbol) {
      return currency.symbol;
    }

    if (currency.isoCode) {
      return getSymbolFromCurrency(currency.isoCode);
    }

    return '';
  }

  ngOnInit() {
    if (!this.editedValue) {
      this.resetEditedValue();
    }

    this.updateLastEditedValue();
  }
}
