/*
 * Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  FullRouterStateSerializer,
  routerReducer,
  RouterStateSerializer,
  StoreRouterConnectingModule
} from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { CacheMemoryStorage, CacheService, CacheStorageAbstract } from 'ng2-cache';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';

import { AppComponent } from './app.component';

import { AppRoutingModule } from './app-routing.module';
import { CsvExporterService } from './shared/services/csv-exporter.service';
import { ErrorHandlerService } from './shared/services/error-handler.service';

import {
  CustomRouteSerializer,
  LuxonDateAdapter,
  MAT_LUXON_DATE_FORMATS,
  marginScreensCommonReducer,
  ScreenEngineModule,
  ScreenEngineResolver,
  ScreenStatusModule,
  AbstractScreenStatusResolver,
  PageWidthLayoutService,
  AbstractCommonScreensResolver,
  ExplainAccountsModule,
  RiskModule,
  ExistingPositionsModule,
  OverviewModule,
  RebalanceScenariosModule
} from '@opengamma/ui';

import { SharedModule } from './shared/shared.module';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import {
  MAT_LEGACY_TOOLTIP_DEFAULT_OPTIONS as MAT_TOOLTIP_DEFAULT_OPTIONS,
  MatLegacyTooltipModule as MatTooltipModule
} from '@angular/material/legacy-tooltip';

import { EffectsModule } from '@ngrx/effects';
import { SharedEffects } from './shared/store/shared/shared.effects';
import { CoreModule } from './core/core.module';
import { UserService } from './user-auth/services/user/user.service';
import { AuthService } from './user-auth/services/auth/auth.service';
import { environment } from 'environments/default.environment';
import { ApiUserService } from './user-auth/services/user/api-user.service';
import { MockAuthService } from './user-auth/services/auth/mock-auth.service';
import { ApiAuthService } from './user-auth/services/auth/api-auth.service';
import { TestAuthService } from './user-auth/services/auth/test-auth.service';
import { UserAuthModule } from './user-auth/user-auth.module';
import { HeaderModule } from 'app/header/header.module';
import { CsvExporterFlatService } from './shared/services/csv-exporter-flat.service';
import { carmaApiPath } from 'environments/environment.utils';
import { storeDevTools } from 'app/build-specifics';
import { MockUserService } from './user-auth/services/user/mock-user.service';
import { NewFeatureFlagService } from 'app/shared/services/new-feature-flag.service';
import { ThemeService } from './shared/services/theme.service';
import { CarmaScreenEngineResolver } from './shared/services/carma-screen-resolver.service';
import { CarmaScreenStatusResolver } from './shared/services/screen-status-resolver.service';
import { CarmaCommmonScreenResolver } from './shared/services/common-screen-resolver.service';
import { JSCCConsentService } from './user-auth/services/auth/jscc-consent.service';
import { MatDialogModule } from '@angular/material/dialog';
import { AppMainComponent } from './app-main/app-main.component';

@NgModule({
  declarations: [AppComponent, AppMainComponent],
  imports: [
    BrowserModule,
    ScreenEngineModule.forRoot({
      resolverProvider: {
        provide: ScreenEngineResolver,
        useClass: CarmaScreenEngineResolver
      },
      apiPath: carmaApiPath()
    }),
    BrowserAnimationsModule,
    HttpClientModule,
    StoreModule.forRoot({ router: routerReducer }),
    storeDevTools,
    EffectsModule.forRoot([SharedEffects]),
    StoreRouterConnectingModule.forRoot({ serializer: FullRouterStateSerializer }),
    SharedModule,
    StoreModule.forFeature('common', marginScreensCommonReducer),
    UserAuthModule,
    CoreModule,
    AppRoutingModule,
    HeaderModule,
    MatDialogModule,
    ScreenStatusModule.forRoot({
      resolverProviders: [
        { provide: AbstractScreenStatusResolver, useClass: CarmaScreenStatusResolver },
        { provide: AbstractCommonScreensResolver, useClass: CarmaCommmonScreenResolver }
      ],
      apiPath: carmaApiPath()
    }),
    ExplainAccountsModule.forRoot({
      resolverProvider: {
        provide: AbstractCommonScreensResolver,
        useClass: CarmaCommmonScreenResolver
      },
      apiPath: carmaApiPath()
    }),
    RiskModule.forRoot({
      resolverProviders: [
        { provide: AbstractCommonScreensResolver, useClass: CarmaCommmonScreenResolver }
      ],
      apiPath: carmaApiPath()
    }),
    ExistingPositionsModule.forRoot({
      resolverProviders: [
        { provide: AbstractCommonScreensResolver, useClass: CarmaCommmonScreenResolver }
      ],
      apiPath: carmaApiPath()
    }),
    OverviewModule.forRoot({
      resolverProviders: [
        { provide: AbstractCommonScreensResolver, useClass: CarmaCommmonScreenResolver }
      ],
      apiPath: carmaApiPath()
    }),
    RebalanceScenariosModule.forRoot({
      resolverProviders: [
        { provide: AbstractCommonScreensResolver, useClass: CarmaCommmonScreenResolver },
        { provide: AbstractScreenStatusResolver, useClass: CarmaScreenStatusResolver }
      ],
      apiPath: carmaApiPath()
    }),
    NgxPageScrollModule,
    NgxPageScrollCoreModule.forRoot({}),
    MatTooltipModule
  ],
  providers: [
    CsvExporterService,
    CsvExporterFlatService,
    PageWidthLayoutService,
    NewFeatureFlagService,
    ThemeService,
    CacheService,
    {
      provide: AuthService,
      useFactory: authServiceFactory,
      deps: [HttpClient]
    },
    {
      provide: UserService,
      useFactory: userServiceFactory,
      deps: [HttpClient]
    },
    JSCCConsentService,
    {
      provide: RouterStateSerializer,
      useClass: CustomRouteSerializer
    },
    {
      provide: CacheStorageAbstract,
      useClass: CacheMemoryStorage
    },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    { provide: DateAdapter, useClass: LuxonDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: MAT_LUXON_DATE_FORMATS },
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: {
        showDelay: 0,
        hideDelay: 0,
        touchendHideDelay: 1000,
        position: 'above'
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

export function userServiceFactory(httpClient: HttpClient): UserService {
  if (environment.disableAuth) {
    return new MockUserService();
  }
  return new ApiUserService(httpClient);
}

export function authServiceFactory(client: HttpClient): AuthService {
  switch (environment.authProvider) {
    case 'api':
      return new ApiAuthService(client);
    case 'local':
      return new TestAuthService(client);
    case 'mock':
      return new MockAuthService();
  }
}
