<!--
  ~ Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->

<div class="container" fxLayout="column">
  <h2 class="error__header">
    This account is being set up...
  </h2>
  <h2 class="error__message">No products have been enabled or enabling is in progress.</h2>
  <h3 class="error__contact">
    If the issue persists please contact us at
    <a class="message__email" href="mailto:support@opengamma.com">support@opengamma.com</a>
  </h3>
</div>
