/*
 * Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { UserDetails } from '@opengamma/ui';
import * as _ from 'lodash';

/**
 * Removes the # and ? arguments from a url, returning only the pathname.
 */
function getSanitizedUrlPath(url: string): string {
  return url.replace(/#.+$|\?.+$/, '');
}

export function getUrlPathSegment(path, entity: 'product' | 'module' | 'screen'): string {
  const sanitizedPath = getSanitizedUrlPath(path);
  switch (entity) {
    case 'product':
      return sanitizedPath.split('/')[1];
    case 'module':
      return sanitizedPath.split('/')[2];
    case 'screen':
      return sanitizedPath.split('/')[3];
    default:
      return undefined;
  }
}

export function getScreenNameFromPath(currentUrl: string): string {
  return _.startCase(
    getUrlPathSegment(currentUrl, 'module') + ' ' + getUrlPathSegment(currentUrl, 'screen')
  );
}

export function isTenantJscc(userDetails: UserDetails): boolean {
  return ['JSCC ETD VaR', 'JSCC Member', 'JSCC Non-Member', 'JSCC ETD'].includes(
    userDetails.companyName
  );
}
