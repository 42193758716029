/*
 * Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Component, Input, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { BarChartParams } from './bar-chart.models';
import { WHITE, SILVER, TUNDORA, THETA, BLACK, BOULDER } from 'app/shared/utils/colors';

@Component({
  selector: 'og-bar-chart',
  templateUrl: 'bar-chart.component.html',
  styleUrls: ['bar-chart.component.scss']
})
export class BarChartComponent implements OnChanges {
  @ViewChild('chart', { static: true }) chartEl: ElementRef;

  /** The bar chart params to use. */
  @Input() chartParams: BarChartParams;

  /** The theme. */
  @Input() theme: 'light' | 'dark' = 'light';

  options: any;

  ngOnChanges() {
    const { xAxisParams, yAxisParams, points } = this.chartParams;
    const data = points.map(point => ({
      data: [point.y],
      name: point.name,
      color: point.color
    }));
    const options = {
      chart: {
        type: 'bar',
        spacingTop: 0,
        spacingBottom: 0
      },
      legend: {
        enabled: this.chartParams.showLegend === undefined ? true : this.chartParams.showLegend,
        itemStyle: {
          color: this.theme === 'light' ? BLACK : SILVER,
          fontWeight: 'normal'
        },
        itemHoverStyle: {
          color: this.theme === 'light' ? BLACK : WHITE,
          fontWeight: 'normal'
        },
        itemHiddenStyle: {
          color: BOULDER,
          fontWeight: 'normal'
        }
      },
      xAxis: {
        enabled: xAxisParams?.isEnabled,
        gridLineColor: '#000000',
        lineWidth: 1,
        visible: xAxisParams?.isEnabled,
        gridLineWidth: 1,
        gridLineDashStyle: 'ShortDash',
        gridZIndex: 5
      },
      yAxis: {
        enabled: true,
        gridLineColor: this.theme === 'light' ? SILVER : TUNDORA,
        gridLineDashStyle: 'dash',
        lineWidth: 1,
        title: {
          text: yAxisParams?.title
        },
        labels: {
          enabled: true,
          formatter: function() {
            return yAxisParams?.formatter(this.value) || this.value;
          },
          style: {
            color: this.theme === 'light' ? TUNDORA : THETA,
            textOverflow: 'none'
          },
          rotation: 0
        },
        plotLines: [
          {
            value: 0,
            color: this.theme === 'light' ? TUNDORA : SILVER,
            width: 1,
            dashStyle: 'Solid'
          }
        ],
        visible: yAxisParams?.isEnabled
      },
      plotOptions: {
        bar: {
          borderWidth: 0,
          maxPointWidth: 20,
          dataLabels: {
            enabled: true,
            style: {
              color: this.theme === 'light' ? BLACK : WHITE,
              borderColor: this.theme === 'light' ? WHITE : BLACK,
              fontWeight: 'normal'
            },
            formatter: (valueFormatter =>
              function() {
                if (valueFormatter) {
                  return valueFormatter(this.y, this.series.name);
                }
                return this.y;
              })(this.chartParams.valueFormatter)
          },
          enableMouseTracking: false
        }
      },
      tooltip: {
        enabled: false
      },
      series: data
    };

    this.options = options;
  }
}
