/*
 * Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import {
  CellForTemplate,
  NestedTableDecoratedRow,
  NestedTableRow,
  PagesDisplayed,
  TableColumn,
  TableColumnGroup,
  NumericBound,
  getColumnsFromColumnGroups,
  CellDataType,
  IconStroke
} from '@opengamma/ui';
import { decorateNestedTableRowWithExpansionMetaData } from 'app/shared/components/table/utils/nested-table.utils';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import _ from 'lodash';

@Component({
  selector: 'og-nested-table',
  templateUrl: './nested-table.component.html',
  styleUrls: ['./nested-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NestedTableComponent {
  @Input() columnGroups: TableColumnGroup[];
  @Input() currentlyDownloadingFiles: Set<string>;
  @Input() bound: NumericBound = 1e6;
  @Input() exportIds: string[];

  @Output() checkboxClick = new EventEmitter<string[]>();

  getColumnsFromColumnGroups = getColumnsFromColumnGroups;

  rowPaginationCount = 25;
  pagesDisplayedForPath: PagesDisplayed = {};

  rowExpansionSet$ = new BehaviorSubject<Set<string>>(new Set());
  rows$: Observable<NestedTableDecoratedRow[]>;

  @Input() set rows(rows: NestedTableRow[]) {
    this.rows$ = this.rowExpansionSet$.pipe(
      map(expansionSet =>
        rows.map(row => decorateNestedTableRowWithExpansionMetaData(row, expansionSet))
      )
    );
  }

  IconStroke = IconStroke;

  getCellForTemplate(row: NestedTableDecoratedRow, { id, type }: TableColumn): CellForTemplate {
    return { value: (row?.values)[id] as CellDataType, type: type };
  }

  onRowExpand(rowId: string): void {
    const expansionSet = this.rowExpansionSet$.value;
    expansionSet.has(rowId) ? expansionSet.delete(rowId) : expansionSet.add(rowId);
    this.rowExpansionSet$.next(expansionSet);
  }

  trackRows(index: number, row: NestedTableRow): string {
    return row?.id;
  }

  getNumberOfRowsToDisplayForPath(path: string[]): number {
    return this.rowPaginationCount * (this.pagesDisplayedForPath[this.getPathAsString(path)] || 1);
  }

  getPathAsString(path: string[]): string {
    return path.join('/');
  }

  onShowMoreRows(path: string[]): void {
    const pathAsString = this.getPathAsString(path);
    this.pagesDisplayedForPath = {
      ...this.pagesDisplayedForPath,
      [pathAsString]: this.pagesDisplayedForPath[pathAsString]
        ? this.pagesDisplayedForPath[pathAsString] + 1
        : 2
    };
  }

  onCheck(exportId: string, row: NestedTableDecoratedRow): void {
    if (row.isExpandable) {
      this.exportIds = row.exportIds.every(rowId => this.exportIds.includes(rowId))
        ? _.difference(this.exportIds, row.exportIds)
        : this.exportIds.concat(row.exportIds);
    } else {
      this.exportIds = this.exportIds.includes(exportId)
        ? this.exportIds.filter(id => id !== exportId)
        : this.exportIds.concat(exportId);
    }

    this.checkboxClick.emit(this.exportIds);
  }

  isIndeterminate(row: NestedTableDecoratedRow): boolean {
    if (row.isExpandable) {
      return (
        !row.exportIds.every(rowId => this.exportIds.includes(rowId)) &&
        row.exportIds.some(rowId => this.exportIds.includes(rowId))
      );
    }
  }

  isChecked(exportId: string, row: NestedTableDecoratedRow): boolean {
    if (row.isExpandable) {
      return row.exportIds.every(rowId => this.exportIds.includes(rowId));
    } else {
      return this.exportIds.includes(exportId);
    }
  }
}
